import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './index.css';

import Home from './Pages/Home';
import Rcomplete from './Pages/rcomplete';
import Lcomplete from './Pages/lcomplete';
import RstudentId from './Pages/rstudentId';
import LstudentId from './Pages/lstudentId';
import Reservation from './Pages/reservation';
import Test from './Pages/Test';

import GlobalStyle from './Components/GlobalStyle';
import LocalStorageObserver from './utils/LocalStorageObserver'; // LocalStorageObserver 임포트
import { initGA, logPageView } from './utils/analytics'; // GA 유틸리티 임포트

// 페이지 뷰를 추적하는 컴포넌트
const PageViewTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    logPageView();
  }, [location]);
  
  return null;
};

// 라우팅과 분석을 포함하는 메인 래퍼
const AppContent = () => {
  return (
    <>
      <LocalStorageObserver />
      <PageViewTracker />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/reservation/:id/studentId" element={<RstudentId />} />
        <Route path="/reservation/:id/complete" element={<Rcomplete />} />
        <Route path="/leave/studentId" element={<LstudentId />} />
        <Route path="/leave/:id/complete" element={<Lcomplete />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </>
  );
};

const App = () => {
  // 앱 마운트 시 GA 초기화
  useEffect(() => {
    initGA();
  }, []);

  return (
    <>
      <GlobalStyle />
      <Router>
        <AppContent />
      </Router>
    </>
  );
};

export default App;
