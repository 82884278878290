import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { seatState } from '../recoil/recoilState';
import { logEvent, logUserFlow } from '../utils/analytics'; // GA 추적 유틸리티 임포트

const SeatStatus = ({ index, status, remainingTime, navigateToReservation }) => {
    const isAvailable = !status;

    const handleSeatClick = () => {
        if (isAvailable) {
            // GA 이벤트 추적 - 좌석 선택
            logEvent('Seat', 'Select', `Seat ${index + 1}`);
            navigateToReservation(index);
        }
    };

    return (
        <div
            className={`flex items-center w-[821px] h-[140px] p-[32px_80px] rounded-[10px] border-[1.5px] 
                ${isAvailable ? 'bg-primary border-gray-400' : 'bg-white border-gray-400'}`}
            onClick={handleSeatClick}
        >
            <span
                className={`text-center font-pretendard text-[57px] font-normal leading-normal tracking-[-1.71px] 
                ${isAvailable ? 'text-white' : 'text-gray01'}`}
            >
                {index + 1}
            </span>
            <div className="flex flex-1 justify-center items-center gap-[31px]">
                <span
                    className={`text-md font-pretendard text-[50px] font-normal leading-normal tracking-[-1.71px] 
                    ${isAvailable ? 'text-white' : 'text-gray01'}`}
                >
                    {isAvailable ? '사용가능' : remainingTime}
                </span>
            </div>
        </div>
    );
};

const SeatsContainer = () => {
    const [seats, setSeats] = useRecoilState(seatState);
    const navigate = useNavigate();

    const calculateRemainingTime = (endTime) => {
        const now = new Date();
        const end = new Date(endTime);

        if (now >= end) {
            return '00:00:00';
        }

        const totalSeconds = Math.floor((end - now) / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // 좌석 상태를 1초마다 체크하는 대신, 종료 시간을 감시
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            setSeats(prevSeats =>
                prevSeats.map(seat => {
                    if (seat.isRented && seat.endTime && !seat.isExpired) {
                        const endTime = new Date(seat.endTime);
                        if (now >= endTime) {
                            // 좌석이 종료되면 상태를 변경하고, 이후에는 체크하지 않음
                            return {
                                ...seat,
                                isRented: false,
                                isExpired: true, // 한 번 종료된 좌석은 체크하지 않도록 flag 추가
                                remainingTime: '00:00:00',
                            };
                        } else {
                            // 종료되지 않은 좌석은 남은 시간을 계속 계산
                            return {
                                ...seat,
                                remainingTime: calculateRemainingTime(seat.endTime),
                            };
                        }
                    }
                    return seat;
                })
            );
        }, 1000); // 1초마다 종료 시간만 체크

        return () => clearInterval(interval);
    }, [setSeats]);

    const navigateToReservation = (index) => {
        // 사용자 흐름 추적
        logUserFlow('ReservationProcess', `Selected Seat ${index + 1}`);
        navigate(`/reservation/${index + 1}/studentId`);
    };

    return (
        <div className="flex flex-col items-center gap-[13px] w-full max-w-[821px] mx-auto">
            {seats.map((seat, index) => (
                <SeatStatus
                    key={index}
                    index={index}
                    status={seat.isRented}
                    remainingTime={seat.isRented ? calculateRemainingTime(seat.endTime) : '00:00:00'}
                    navigateToReservation={navigateToReservation}
                />
            ))}
        </div>
    );
};

export default SeatsContainer;
