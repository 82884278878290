import ReactGA from 'react-ga4';

// GA 초기화 함수
export const initGA = () => {
  // 개발 환경에서는 GA를 초기화하지 않음 (선택사항)
  if (process.env.NODE_ENV === 'development') {
    console.log('GA initialized in development mode');
  }
  
  // GA 초기화 (제공된 측정 ID 사용)
  ReactGA.initialize('G-XHEW5FG1YB');
};

// 페이지 뷰 추적
export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

// 이벤트 추적
export const logEvent = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

// 버튼 클릭 이벤트를 위한 편의 함수
export const logButtonClick = (buttonName, additionalInfo = '') => {
  logEvent('Button', 'Click', `${buttonName} ${additionalInfo}`.trim());
};

// 사용자 흐름 추적을 위한 함수
export const logUserFlow = (flowStep, additionalInfo = '') => {
  logEvent('UserFlow', flowStep, additionalInfo);
};

// 사용자 ID 설정 (로그인 후 사용)
export const setUserId = (userId) => {
  ReactGA.set({ userId });
}; 